/**
 * Docket Alarm
 * Javascript Utility Functions - Billing Related
 *
 * Within Scope:
 *   Anything billing related when user is logged out of account
 * 	 Signup / Setup Account
 * 	 Paywall
 * 	 Membership panels
 * 	 Basic billing information pull for when user first logs in.
 *
 * Out of Scope:
 * 	Billing history
 * 	Most logged in billing features
 * 		Exception: related to logging in / signing up.
 *
 */

import {
	analytics_track,
	borderless_dialog,
	capitalize,
	create_html_table,
	get_parent_with_class,
	get_parent_with_tag,
	load_css,

	makeCustomEvent,
	onEvent,
	dispatchEvent,

	sessionClear,
	sessionGet,
	sessionSet,

	setup_marketing_inquiry_form,

	show_confirm_usermsg,
	show_error_usermsg,
	show_success_usermsg,
	show_usermsg,

	update_label_widths,

	SEARCH_ALL_STR
} from "../site_media/common";

/*********************************************************************
 *
 *  Billing
 *
 *********************************************************************/
var setup_billing_overlay_callback = [];
var last_billing_info = null;

/**
 * Parse a response error, checking if its a matter error. If it is, and the matter is visible,
 * create the error on the matter. If not, return false.
 * @param resp
 * @param $form_hint The form to show the matter_error on. If not given, will try to
 * 	find the default matter number on the top.
 * @returns {boolean}
 */
function set_matter_number_error(resp, $form_hint) {
	if(resp.error_type != 'billing.matter') {
		return false;
	}
	// If not given a hint, try to find the default matter number on the top.
	var $form_with_matter = $form_hint ||
		$(".userinfoform input[name=pacerdefaultmatter]").parents("form");
	// If not visible, don't show error.
	if (!$form_with_matter.length || !$form_with_matter.is(":visible")) {
		return false;
	}
	// If not scrolled into view, don't show the error.
	if($(window).scrollTop() > $form_with_matter.offset().top) {
		return false;
	}
	// Show the matter number error.
	$form_with_matter.addClass("matter_error");
	show_error_usermsg(resp.error, resp.error_type, $form_with_matter);
	return true;
}

/**
 * Sets up the billing pane. Also displays the free trial/account type window
 * if necessary. Also displays any pertinent billing messages anywhere.
 * Does NOT make the billing pane visible.
 * @param info			User information.
 * @param from_cache	True if the data came from a cache (and may be stale)
 */
function setup_billing_pane(info, from_cache) {
	if(!info) {
		return;
	}
	// We show a different narrative depending on the tye of account they have.
	var narrative_class = info.billing_type;
	var $billing_pane = $(".billing_pane")
		.find(".billing_narrative").hide().end();

	// Fill in values for the pacerlogin and pacer password
	$("input[name=pacerlogin]").val(info.pacerlogin);
	$("input[name=pacerpassword]").val(info.pacerpassword);
	// Set the default matter number if it makes sense to do so.
	$("input[name=pacerdefaultmatter]").each(function () {
		// Get the existing value.
		var cur_val = $(this).val();

		// Decode into other possible values.
		var cur_vals = {};
		cur_vals[cur_val] = true;
		if(cur_val && cur_val[0] == '{') {
			var js_val = JSON.parse(cur_val);
			if(js_val.description) {
				cur_vals[js_val.description] = true;
			}
			cur_vals[js_val.id] = true;
		}
		if(cur_vals[info.pacerdefaultmatter]) {
			// No change needed.
		} else if(cur_val && cur_val != info.pacerdefaultmatter) {
			// There is an existing value, and it doesn't match. Happen if
			// billing info is out of date compared to HTML. Give a warning.
			if(!from_cache) {
				console.warn("PACER matter mismatch: " + cur_val + " != " +
					info.pacerdefaultmatter);
			}
		} else {
			// Setting the matter number.
			$(this).val(info.pacerdefaultmatter);
		}
	});

	// Extra PACER Logins
	var pacer_extra = (info.integration || {}).pacer_extra || {};
	var pacer_arr = [];
	for(var k in pacer_extra) {
		pacer_arr.push({name:k, info:pacer_extra[k]});
	}
	if(pacer_arr.length) {
		// Convert this object to an array.
		// Create the table.
		var headings = ['Name', 'PACER Username', 'Default Matter', 'Action'];
		var table = create_html_table(pacer_arr, headings, function (obj) {
			return [obj.name, obj.info.pacerlogin, obj.info.pacerdefaultmatter,
				'<span class="remove" data-name="' + obj.name +
					'">Remove</span>'];
		});
		$(".extra_pacer_accounts").html(table)
			.find(".remove").click(function () {
				var $btn = $(this);
				var data = {pacername_extra_remove:$btn.data('name')};
				$.post( "/set_user_info.ajax", data, function(resp) {
					if(resp.success) {
						show_usermsg("Removed.", $btn);
						// Give it a pause to let the db update.
						setTimeout(function () {
							update_billing_info(true);
						}, 800);
					} else {
						show_error_usermsg(resp.error);
					}
				}, 'json');
			});
	} else {
		$(".extra_pacer_accounts").html("");
	}

	// Turn on all powertips that have data.
	/// Problematic
	$billing_pane.find("[data-powertip]").powerTip({
		smartPlacement : true,
		// These are basic powerTips defined by attributes, style accordingly.
		popupClass : 'tagtip'
	});

	var bill_descrip = "";
	// Reset menu items
	disable_pacer_submenus(true);

	// Setup the account setup
	var Recur = info.user_pricing.Recur;
	var products_pricing_description = ((info.custom_pricing || {}).other || {}).products_pricing_description;
	var $flat_rate = $billing_pane.find("form select[name=flat_rate]")
		.find("option[value=Fed]")
			.toggle(Recur.Fed != null)
			.text("Flat Rate - Federal Courts ($" + (Recur.Fed ||"").toString() + "/mo.)")
			.end()
		.find("option[value=PTOITC]")
			.toggle(Recur.PTOITC != null)
			.text("Flat Rate - The PTAB and ITC ($" + (Recur.PTOITC ||"").toString() + "/mo.)")
			.end()
		.find("option[value=All]")
			.toggle(Recur.All != null)
			.text("Flat Rate - " + SEARCH_ALL_STR + " ($" +
				(Recur.All ||"").toString() + "/mo.)" + ((products_pricing_description) ?
					" + " + products_pricing_description : ''))
			.end()
		.val(info.user_pricing.Recur.flat_rate || "None")
		.find("option[value=personal]").remove().end();

	// Do not show the PACER input fields if they don't need it. Note that this
	// only applies to paid users, not to the free trial people.
	var show_pacer_info = !info.user_pricing.other.pacer_fees_billed ||
		(info.user_pricing.other.paygo_user_pacer_account &&
			!info.user_pricing.Recur.flat_rate);
	// Don't show PACER information adjustments to non-admins.
	if(show_pacer_info && info.billing_type == "corporate" &&
		!info.user_can_edit) {
		show_pacer_info = false;
	}
	$billing_pane
		.find("form.setpacerinfo").toggle(show_pacer_info).end()
		// Hide this for now, we unhide below if necessary.
		.find("form.setpacerinfo_freetrial").hide();

	if(info.billing_type == 'personal') {
		// Paid Account
		bill_descrip += "Paid Account.";
		// Don't let them view the membership page anymore.
		$("a[href='#MembershipOptions']").each(function () {
			// Hide the link
			var p = $(this).hide().parent()[0];
			// And hide any list object it belongs to.
			if(p && p.nodeName.toLowerCase() == "li") {
				$(p).hide();
			}
		});
		if(info.next_charge_date)
			bill_descrip += " Next bill due on " + info.next_charge_date + ".";
		var account_type = {
				Fed		: "Federal Court flat rate",
				PTOITC	: "PTAB and ITC flat rate",
				All		: "flat rate",
				PayAsGo	: "Pay-As-You-Go"
			}[info.user_pricing.Recur.flat_rate || "PayAsGo"];

		if(last_billing_info && last_billing_info.billing_type == "pacer") {
			// Congratulate them on upgrading.
			show_success_usermsg("Your membership has been upgraded.");
			analytics_track("billing", "account_upgraded", account_type);
		}
		toggle_membership_window(false);
		// In the testimonial window, don't say anything about free trials.
		$billing_pane.find(".account_type")
			.find("form.set_account_type input[type=submit]").button({disabled:false}).end()
			.find(".account_msg").text("Your account type is set to " + account_type + " pricing. ");
	} else if(info.billing_type == 'corporate') {
		bill_descrip += "Group billing.";
		toggle_membership_window(false);
		// Don't let them view the account type page.
		$("a[href='#MembershipOptions']").hide();
		// Setup the account buttons
		$billing_pane.find(".account_type")
			.find("form.set_account_type input[type=submit]").button({disabled:true}).end()
			.find(".account_msg").html(
				"<span class='bill_warn'>Warning</span>: Your account is " +
				"a corporate billing account and cannot be changed here.  " +
				"Contact support@docketalarm.com for billing inquiries.");
	} else if(info.billing_type == 'pacer') {
		// During the free trial, the user may either have PACER credentials
		// entered or a credit card entered to bill court fees.
		var cards_info = sessionGet("cards_info");
		var free_with_credit_card = cards_info && cards_info.length > 0;
		// True if they entered their pacer account details or a credit
		// card, i.e., some way to pay for court fees.
		var free_w_pacer = free_with_credit_card || (
						info.pacerlogin != null &&
						info.pacerpassword  != null &&
						info.pacerlogin.length > 0 &&
						info.pacerpassword.length > 0);
		// True when there's no pacer details, and should show the message.
		var free_wo_pacer = !free_w_pacer && info.dontshow_pacer_msg &&
			!free_with_credit_card;

		// Figure out our previous state so we can show a message
		var had_free_w_pacer = free_with_credit_card || (
					last_billing_info != null &&
					last_billing_info.pacerlogin != null &&
					last_billing_info.pacerpassword  != null &&
					last_billing_info.pacerlogin.length > 0 &&
					last_billing_info.pacerpassword.length > 0);
		var had_free_wo_pacer = last_billing_info != null &&
						!had_free_w_pacer &&
						last_billing_info.dontshow_pacer_msg;

		narrative_class = !info.had_free_trial ? 'no_trial' :
								info.trial_days_left <= 0 ? 'pacer_expired':
								free_with_credit_card ? 'pacer_creditcard':
								'pacer';
		if(info.trial_days_left <= 0 && info.had_free_trial) {
			// The free trial is over
			bill_descrip += "<b>Trial has expired!</b> Upgrade your account.";
			// Warn then when they log in.
			if(last_billing_info == null) {
				show_error_usermsg(bill_descrip);
				// Direct them to the billing page.
			}
			toggle_membership_window(true);
		} else if(info.trial_days_left <= 0) {
			// They never had a free trial
			bill_descrip += "<a href='#MembershipOptions'>Set up your" +
				" account</a>.";
			toggle_membership_window(true);
		} else if(!free_w_pacer && !free_wo_pacer) {
			// They have not yet selected an account.  Direct them to the
			// account selection page.
			bill_descrip += "Setup account.";
			disable_pacer_submenus(false, false);
			// Don't show signup page if free trial, even if not full.
			// toggle_membership_window(true);
			// Allow them to change their PACER username/password.
			$billing_pane.find("form.setpacerinfo_freetrial").show();
		} else if(free_w_pacer || free_wo_pacer) {
			// A valid free trial
			var free_trial_type = free_w_pacer ? "trial":"unactivated account";
			// Make sure free trial is selected in the dropdown.
			$flat_rate.append("<option value=personal>" +
				capitalize(free_trial_type)+ "</option>").val("personal");
			// Show the account type link during the free trial
			$("a[href='#MembershipOptions']").show();
			if(!free_with_credit_card) {
				// Allow them to change their PACER username/password.
				$billing_pane.find("form.setpacerinfo_freetrial").show();
			}
			if(last_billing_info != null && (
									had_free_w_pacer != free_w_pacer ||
									free_wo_pacer != had_free_wo_pacer)) {
				// They just created the free trial, show a message
				show_success_usermsg("Your " + free_trial_type +
									" is now activated.");
			}

			bill_descrip += "<b>" + info.trial_days_left + " days " +
						"left</b> for " + free_trial_type + ".";
			// Close the billing page if it's open
			toggle_membership_window(false);
			// Disable the PACER searches if necessary
			disable_pacer_submenus(false, true, free_w_pacer);
		} else {
			// This should not happen
			show_error_usermsg("Bad account type!");
		}
		if(info.had_free_trial) {
			$(".trial_days_left").text(info.trial_days_left);
			$billing_pane.find(".account_type")
				.find("form.set_account_type input[type=submit]").button({disabled:true}).end()
				.find(".account_msg").text("Contact support@docketalarm.com" +
				 " to change your account type during the trial.");
		} else {
			$(".trial_days_left").text(info.trial_days_left);
			$billing_pane.find(".account_type")
				.find("form.set_account_type input[type=submit]").button({disabled:true}).end()
				.find(".account_msg").text("Add a credit card to change" +
				 " your account.");
		}
	}
	$billing_pane.find(".billing_narrative." + narrative_class ).show().end();
	$("#current_billing").html(bill_descrip);

	if(info.unsubscribed) {
		// We could arguably log them out automatically here. But lets not.
		show_success_usermsg("Your account is not active. Please log out " +
			"and log back in to activate.");
	}

	update_label_widths(".billing_pane");
	setup_billing_overlay_callback.map(function (callback) {
		callback(info);
	});

	// FIXME: If the user's billing type changed, then do another full update
	// to  get an updated narrative, etc. We shouldn't need to do this though.
	if(last_billing_info && last_billing_info.billing_type != info.billing_type) {
		update_billing_info(true);
	}

	last_billing_info = info;
}

/**
 * Sets up the enabled/disabled state of the sub-menus.
 *
 * reset			Turns everything back on.
 * has_pacer		If true, everything is enabled.
 * has_account_type If false, they have not selected an account type.
 */
function disable_pacer_submenus(reset, has_account_type, has_pacer) {
	var court_items  = "#Federal_Courts, #Bankruptcies_Courts, #The_ITC, #The_USPTO, ";
	court_items += "[for=Federal_Courts], [for=Bankruptcies_Courts], [for=The_ITC], [for=The_USPTO]";
	if(reset || has_pacer) {
		$("#menu")
			.find(court_items)
				.removeClass("disabled")
				.find("a")
					.powerTip('destroy').end();
	} else if(!has_account_type) {
		$("#menu")
			.find(court_items)
				.addClass("disabled")
			.find("a")
				.powerTip('destroy')
				.powerTip({
							smartPlacement:true,
							mouseOnToPopup:true
					}).data('powertiptarget', 'select_account_tooltip')
				.end();
	} else {
		$("#menu")
			.find("#Federal_Courts, #Bankruptcies_Courts")
				.addClass("disabled")
				.find("a")
					.powerTip('destroy')
					.powerTip({
						smartPlacement:true,
						mouseOnToPopup:true
					}).data('powertiptarget', 'disabled_tooltip').end()
				.end()
			.find(".submenuitem[for=Federal_Courts], .submenuitem[for=Bankruptcies_Courts]")
				.addClass("disabled").end();
	}
}

/**
 * Get the user's billing information, and saves it in their local session
 * storage for 10 minutes.
 * @param force true to force pulling new data.
 * @param done callback function of the form function done(user_info, synchronous, error)
 * 	error can be a string if it was an ajax error, or a dict if a http error.
 * @returns {boolean|*} the info if it was cached, false if we need to fetch.
 */
function update_billing_session_info(force, done) {
	var info = sessionGet("billing_info", true);
	if(info && !force) {
		if(done) {
			done(info, true);
		}
		return info;
	}
	$.getJSON("/get_user_info.ajax", function () { })
	.success(function(resp) {
		if(resp.success) {
			sessionSet("billing_info", resp.user_info, 10 * 60, true);
			if(done) {
				done(resp.user_info, false);
			}
		} else if (done) {
			done(null, false, resp.error);
		}
	})
	.error(function(error) {
		if(done) {
			done(null, false, error);
		}
	});
	return false;
}

/**
 * Get the user's billing data from the server (if force==true) or
 * try to get a cached version, and then setup the billing overlay.
 *
 * The user must be logged in to use this function.
 */
function update_billing_info(force) {
	// Group Billing isn't available on every screen (e.g., documents).
	if(typeof update_groupbilling_info == "function") {
		var groupbilling_info = force ? null : sessionGet("groupbilling_info");
		update_groupbilling_info(null, groupbilling_info);
	}

	var info = update_billing_session_info(force, function _done(user_info, synchronous, error) {
		$('.billing_pane .loader').hide();
		if(user_info) {
			setup_billing_pane(user_info, synchronous);
			// Only update the cards list when the billing info changes
			var card_list = force ? null : sessionGet("cards_info");
			card_list_helpers.update_card_list(null, card_list, user_info);
		} else if(error && typeof error == "string") {
			var hash = $.bbq.getState();
			if(hash.embed != undefined) {
				// In embedded mode, don't complain about user login.
				return;
			}
			var params = $.deparam(window.location.search.slice(1));
			if(params.login_encrypted) {
				// We're used an encrypted login, so shouldn't access user functions.
				return;
			}
			show_error_usermsg(error);
		} else if(error && error.status != 0 || error.readyState != 0) {
			show_error_usermsg(error.status + ": " + error.statusText);
		}
	});
	if(info == false) {
		$('.billing_pane .loader').show();
	}
}

/**
 *
 */
function init_matter_inputs() {
	// Special setup for the userinfo form at the top of the
	$(".actions .userinfoform input").change(function () {
		$(this).parents("form").first().submit();
	});
}

function init_billing_info_callback() {
	// Save The Billing Info
	$('form.userinfoform, .userinfoform form').ajaxForm({
		beforeSubmit: function (arr, $form) {
			$form
				.find('.loader').show().end()
				.find('input[type=text]').addClass("changing").end()
				// Remove any existing powertips.
				.removeClass("matter_error")
				.powerTip('hide', true);
		},
		success: function (resp, statusText, xhr, $form) { // post-submit callback
			$form.find('.loader').hide().end()
				.find('input[type=text]').removeClass("changing");
			if (resp.success) {
				if ($form.attr('action').search("adminas") > 0) {
					// This was an administrator changing a setting.
					var $submit = $form.find("input[type=submit]");
					show_usermsg("Changed", $submit.length ? $submit : null);
				} else {
					sessionSet("billing_info", resp.user_info, 10 * 60, true);
					sessionClear("cards_info");
					update_billing_info($form.hasClass("force_billing_update"));
				}
			} else if(set_matter_number_error(resp)) {

			} else {
				show_error_usermsg(resp.error, resp.error_type);
			}
		},
		error: function (dum, the_error) {
			$(this).find('.loader').hide();
			show_error_usermsg(the_error)
		},
		dataType: 'json'        // 'xml', 'script', or 'json' (expected server response type)
	});
}

/////////////////////////
// Credit Cards - Adding

/**
 * Setup and show a credit card form given a credit card form holder (i.e.
 * a div to place a cc form).
 * @param use_placeholder If true, display the form in a slightly different
 * style using placeholder text rather than labels.
 * @param for_freetrial If true, adding the credit card should only be used
 * for the free trial, and should not be upgraded to a full account. The
 * user must already be on a free trial for it to work.
 * @param success_callback An optional callback if the card was properly added.
 */
function setup_credit_card_form(cc_form_holder, use_placeholder,
								for_freetrial, success_callback) {
	if(!cc_form_holder || !cc_form_holder.length) {
		// No holder on page to show.
		return;
	}
	if(cc_form_holder.find(".credit-card-form").length > 0) {
		// Already setup, just show it
		cc_form_holder.find(".credit-card-form").show();
		return;
	}
	var cc_form = $(".credit-card-form.original").clone()
			.removeClass("original").show()
			.appendTo(cc_form_holder);
	// parent form is the form that surrounds cc_form
	var parent_form = cc_form_holder[0].tagName.toLowerCase() == "form" ?
		// It's the card holder itself.
		$(cc_form_holder[0]) :
		// We have to go up the parent tree.
		get_parent_with_tag(cc_form_holder, "form");
	if (!parent_form || !parent_form.length) {
		console.log("Cannot find CC's holder.");
		return;
	}
	// Make sure we have the Stripe script, before we start setting up the form.
	card_list_helpers.get_stripe_script();
	if(use_placeholder) {
		// Make a more space efficient credit card form that relies on
		// placeholders to label the items.
		// instead.
		cc_form.find("input[type=text]").each(function _eachinput(v) {
			var $this = $(this);
			var $parent = get_parent_with_class($this, "form-row");
			if($parent.find("input[type=text]").length != 1){
				return;
			}
			var $label = $parent.find("label");
			if($label.length != 1) {
				return;
			}
			// Hide the label.
			var label_txt = $label.hide().text();
			// Add the label to the placeholder text.
			$this.attr('placeholder', label_txt);
		});
		// We need to handle some special cases.
		cc_form.find(".card-city").attr("placeholder", "City")
			.parent().find("label").hide();
		cc_form.find(".card-state").attr("placeholder", "State");
		// Move the zip into the city/state.
		cc_form.find("#Zip").appendTo(cc_form.find(".card-state").parent())
			.attr("size", 8);
		// Move the credit card icons up to the CC line.
		cc_form.find(".card_icons").appendTo(cc_form.find("#card1").parent());
		cc_form.find(".form-row.icon-row").html("");
		cc_form.find("#card1").attr("size", 20);
		// Move the CVC into the expiration line.
		cc_form.find("#cvc1").appendTo(cc_form.find("#expmo1").parent())
			.attr("size", 8)
			// Hide the cvc details and make it a powertip.
			.data("powertip", cc_form.find(".cvc_details").hide().text())
			.powerTip({smartPlacement:true});

	} else {
		update_label_widths(parent_form);
	}

	parent_form.validationEngine({
		onValidationComplete:function(form, valid) {
			if(valid)
				return true;
			setTimeout(function () {
				form.validationEngine('hideAll');
			}, 3000);
			return false;
		}
	});
	cc_form.find(".submit_button").button({
		icons: { primary: "ui-icon-locked" }
	}).click(function _on_submit_click(event) {
		if (!is_card_address_valid(cc_form)) {
		  	show_error_usermsg("Cannot add credit card. Address is missing");
		  	return;
		}
		if (typeof Stripe === "undefined") {
			setTimeout(function () {
				_on_submit_click(event);
			}, 100);
			return;
		}

		parent_form
			.find(".submit_button").addClass("changing").button('disable').end()
			.find(".loader").show();
		Stripe.createToken({
			name : cc_form.find('.card-name').val(),
			address_line1:	cc_form.find('.card-address-1').val(),
			address_line2:	cc_form.find('.card-address-2').val(),
			address_city:	cc_form.find('.card-city').val(),
			address_state:	cc_form.find('.card-state').val(),
			address_zip:	cc_form.find('.card-zip').val(),
			address_country:cc_form.find('.card-country').val(),
			number: 	cc_form.find('.card-number').val(),
			cvc: 		cc_form.find('.card-cvc').val(),
			exp_month: 	cc_form.find('.card-expiry-month').val(),
			exp_year: 	cc_form.find('.card-expiry-year').val()
		}, function (status, response) {
			return card_list_helpers.stripeResponseHandler(parent_form, status, response,
				for_freetrial, success_callback, function () {
					parent_form
						.find(".submit_button").removeClass("changing").end()
						.find(".loader").hide();
			});
		});
	});
	return cc_form;
}
/**
 * This function validates the Credit Card Billing address.
 * We need the address to determine the Sales Tax for each user
 * @param cc_form: The Credit Card form that user enters billing and cc details on
 * @returns {boolean}: Whether the address is filled or not
 */
function is_card_address_valid(cc_form){
    var address_line1 = cc_form.find(".card-address-1").val();
    var address_city = cc_form.find(".card-city").val();
    var address_state = cc_form.find(".card-state").val();
    var address_zip = cc_form.find(".card-zip").val();
    var address_country = cc_form.find(".card-country").val();
    return !(address_line1 == "" || address_city == "" || address_state == ""
        || address_zip == "" || address_country == "");
}
var card_list_helpers = {
	/**
	 * Display the list of credit cards and handle deleting credit cards when asked.
	 *
	 * Not intended to be called directly. Instead, call update_billing_info, which
	 * calls this function as necessary.
	 */
	update_card_list : function update_card_list(base, cards_info, user_info) {
		if(!cards_info) {
			$.getJSON("/get_creditcard.ajax", {}, function(resp) {
				if(resp.success) {
					// Save credit card info in a cookie so we can reuse.
					sessionSet("cards_info", resp.cards_info, 10 * 60);
					card_list_helpers.update_card_list(base, resp.cards_info, user_info);
				} else {
					// show_error_usermsg(resp.error);
					if(!base) {
						base = $(".billing_pane .card-list");
					}
					base.html("There was an issue contacting our credit card processor " +
						"and we are unnable to obtain all of your account details. " +
						"Let us know if your issue is urgent at support@docketalarm.com.");
				}
			});
			return;
		}
		if(!base)
			base = $(".billing_pane .card-list");
		var $billing_pane = get_parent_with_class(base, "billing_pane");
		base.empty();
		// Don't show the card list to users that cannot edit.
		if(user_info && !user_info.user_can_edit) {
			if($billing_pane)
				$billing_pane.addClass("cannot_edit");
			return;
		}
		if($billing_pane)
			$billing_pane.removeClass("cannot_edit");
		if(cards_info.length == 0) {
			// Show the credit card form.
			setup_credit_card_form($(".billing_pane .card-form-holder"));
			// Hide the add card button.
			$(".billing_pane .AddCard").hide();
			if(last_billing_info && last_billing_info.billing_type == "personal") {
				$(".billing_narrative").hide();
				$(".billing_narrative.no_cards").show();
			}
		} else {
			function int_zero_pad(num, pad) {
				var s = num + "";
				while (s.length < pad) s = "0" + s;
				return s;
			}
			for(var i=0; i < cards_info.length; i++) {
				var card = cards_info[i];
				var card_img = card_list_helpers.getCardImg(card.type, true);
				$(".credit_card_info.original").clone().removeClass("original")
					.find('.card_icons').attr('src',card_img).end()
					.find(".cname").text(card.name).end()
					.find(".caddress").text(card.address).end()
					.find(".czip").text(int_zero_pad(card.zip, 5)).end()
					.find(".ctype").text(card.type).end()
					.find(".clast4").text("xxx-" + card.last4).end()
					.find(".clastexpmonth").text(card.exp_month).end()
					.find(".clastexpyear").text(card.exp_year).end()
					.find(".delete_card").attr('cardid', card.id).end()
					.find(".make_default").attr('cardid', card.id).end()
					.toggleClass("default_card", card.default_card)
					.appendTo(base);
			}
			$(".billing_pane")
				.find(".credit-card-form").hide().end()
				.find(".AddCardTitle").hide().end()
				.find(".AddCard").show();

			base.find(".delete_card").click(function() {
				var last4 = $(this).parent().parent().find(".clast4").text();
				if(last4 && last4.length) {
					last4 = " ending in " + last4;
				}
				var answer = confirm ("Are you sure you want to remove the credit "+
					"card" + last4 + "?");
				if (!answer)
					return false;
				get_parent_with_class($(this), "credit_card_info").find(".loader").show();
				$.post("/delete_creditcard.ajax", {id:$(this).attr('cardid')}, function(resp) {
					$(".billing_pane .loader").hide();
					if(resp.success) {
						// Save credit card info in a cookie so we can reuse.
						sessionSet("cards_info", resp.cards_info, 10 * 60);
						card_list_helpers.update_card_list(base, resp.cards_info, user_info);
					} else {
						show_error_usermsg(resp.error);
					}
				}, "json");
				return false;
			}).end()
			.find(".make_default").click(function() {
				get_parent_with_class($(this), "credit_card_info").find(".loader").show();
				$.post("/set_default_creditcard.ajax", {id:$(this).attr('cardid')}, function(resp) {
					$(".billing_pane .loader").hide();
					if(resp.success) {
						// Save credit card info in a cookie so we can reuse.
						sessionSet("cards_info", resp.cards_info, 10 * 60);
						card_list_helpers.update_card_list(base, resp.cards_info, user_info);
					} else {
						show_error_usermsg(resp.error);
					}
				}, "json");
				return false;
			});
		}
		// Let everyone know we finished.
		dispatchEvent(makeCustomEvent('card_list_updated'));
	},

	/**
	 * Stripe add credit card callback function. Called once the stripe ajax call
	 * is complete and we recieved the stripe token. Continues the add credit card
	 * process.
	 */
	stripeResponseHandler: function stripeResponseHandler(parent_form, status, response, for_freetrial,
														  success_callback, done) {
		if (response.error) {
			parent_form.find('.loader').hide();
			parent_form.find(".submit_button").button('enable');
			//show the errors on the form
			show_error_usermsg(response.error.message);
			return;
		}
		var out_data = {
			stripe_token: response.id,
			name: response.card.name,
			address: response.card.address_line1,
			zip: response.card.address_zip,
			// Newer versions of Stripe use "brand", not "type", but be compatible.
			type: response.card.brand || response.card.type,
			last4: response.card.last4,
			exp_month: response.card.exp_month,
			exp_year: response.card.exp_year
		};
		if (for_freetrial) {
			out_data.stay_free_trial = true;
		}
		$.post("/add_creditcard.ajax", out_data, function (result) {
			parent_form
				.find('.loader').hide().end()
				.find(".submit_button").button('enable');
			if (result.success) {
				if (parent_form && parent_form.attr('action') == '/set_user_info.ajax') {
					// After adding the credit card, we must set the user account.
					parent_form.submit();
				}
				update_billing_info(true);
				if (success_callback) {
					success_callback(result);
				}
			} else {
				// Update the billing even if there was a failure b/c we still
				// may have deleted older cards.
				update_billing_info(true);
				// Show the message after the call so the ajax has time to run.
				show_error_usermsg(result.error);
			}
			if (done) {
				done();
			}
		}, "json");
	},

	getCardImg: function getCardImg(card_type, big) {
		var base = "/site_media/img/credit-cards/";
		base += big ? "big_" : "";
		return base + ({
			"Visa" : "visa_icon.png",
			"MasterCard" : "mastercard_icon.png",
			"American Express" : "amex_icon.png",
			"Discover" : "discover_icon.png",
			"Diners Club" : "diners_icon.png",
			"JCB" : "jcb_icon.png"
		}[card_type] || "all_icons.png");
	},
	_has_scripts : {},
	_is_loading : false,
	get_stripe_script : function get_stripe_script(stripe_key) {
		if(!stripe_key || !stripe_key.length) {
			stripe_key = stripe_public_key;
		}
		if(this._has_scripts[stripe_key]) {
			return;
		}
		if(this._is_loading) {
			return;
		}
		// Get Stripe code asynchronously.
		if (!stripe_key || !stripe_key.length) {
			console.log("No stripe key, will not load stripe.");
			return;
		}
		var t = this;
		t._is_loading = true;
		$.getScript("https://js.stripe.com/v1/", function _got_stripe() {
			t._is_loading = false;
			if (typeof Stripe !== "undefined") {
				Stripe.setPublishableKey(stripe_key);
				t._has_scripts[stripe_key] = true;
			}
		});
    },
	checkCardNum : function checkCardNum(field, rules, i, options) {
		if (!Stripe) {
			// Don't validate if we can't.
			return true;
		}
		if (!Stripe.validateCardNumber(field.val()))
			return "Invalid credit card number.";
		$(".credit-card-form img.card_icons").attr("src", card_list_helpers.getCardImg(Stripe.cardType(field.val())));
	},
	checkDate : function checkDate(field, rules, i, options) {
		if (!Stripe) {
			return true;
		}
		var parent_form = field.parent();
		if (!Stripe.validateExpiry(
			parent_form.children('.card-expiry-month').val(),
			parent_form.children('.card-expiry-year').val()))
			return "Bad expiration date.";
	},
	checkCVC : function checkCVC(field, rules, i, options) {
		if (!Stripe) {
			return true;
		}
		if (!Stripe.validateCVC(field.val()))
			return "Bad CVC code";
	}
}


function toggle_membership_window(show) {
	if(show) {
		show_membership_options_overlay();
	} else {
		// Do the same with the membership page if it's open.
		var $memberoptions = $("#MembershipOptions");
		if($memberoptions.is(":visible"))
			$memberoptions.dialog("close");
	}
}
function show_membership_options_overlay(force_now) {
	var cards_info = sessionGet("cards_info");
	if(user_logged_in && !cards_info && !force_now) {
		// Card data is coming in any second, wait for it..
		setTimeout(function () {
			show_membership_options_overlay(true);
		}, 1000);
		return;
	} else if (user_logged_in && cards_info === null) {
		console.log("Showing user data witout card info");
	}

	var $options_window = $("#MembershipOptions");
	var has_cards = cards_info && cards_info.length > 0;
	if (has_cards) {
		// We have a credit card on file, however our billing type is still
		// set to free trial and its expired.  This is an error state and
		// should not occur, but handle it gracefully.
		// Build our html;
		var $options = $options_window
			.find(".memoption.first").clone()
			.find("input").remove().end();
		var $msg = $("<p>Would you like to use the card on file " +
			"to upgrade your account?</p>").append($options)
			.find(".PACER_tip").powerTip({
				smartPlacement: true,
				mouseOnToPopup: true,
				closeDelay: 400,
			}).data('powertip', $("#PACER_explained").html()).end();

		if ($options_window.is(":visible")) {
			$options_window.dialog("close");
		}
		show_confirm_usermsg({
			title: last_billing_info && last_billing_info.had_free_trial ?
				"Free Trial Expired" : "Upgrade Account",
			subtitle: $msg,
			okay_msg: "Yes, Upgrade",
			cancel_msg: "No",
			okay: function () {
				$("form#upgrade_account").submit();
			},
			dialog_options: {
				dialogClass: "UpgradeAccountMsg"
			}
		});
		return;
	}
	function do_show() {
        var position = {my: "center center-2%", at: "center", of: window};
        var $dialog = borderless_dialog("#MembershipOptions", {
            dialogClass: "MembershipOptionsParent"
        })
		.dialog("open")
		.find(".card-form-holder").each(function () {
			var $this = $(this);
			setup_credit_card_form($this);
			$this.find(".submit_button .card_msg").text("Become a Member");
			$options_window.dialog("option", "position", position);
			$(window).resize(function (e) {
				$options_window.dialog("option", "position", position);
			});
		}).end()
		.find(".memoption").click(function () {
			$dialog.find(".memoption").removeClass("selected");
			$(this).addClass("selected")
				.find("input[type=radio]").prop("checked", true);

		}).find("[data-powertip]").powerTip({
				smartPlacement: true,
				popupClass: 'small_powertip',
				closeDelay: 400,
			}).end().end();
	}
	if($options_window.is(":visible")) {
		// Don't display if already visible.
		;
	} else if(typeof groupbilling_ask_join !== "undefined") {
		// Ask the user if they'd like to join the billing group first.
    	groupbilling_ask_join(null, do_show);
	} else {
		// Show the normal membership dialog.
		do_show();
	}
}


$(document).ready(function _start() {
	init_matter_inputs();
	setup_signup_dialogs();
    setTimeout(function common_setup_later() {
        init_billing_info_callback();
		$(".calendly_demo").click(calendly_demo_click);
		$("a[href='#MembershipOptions']").click(function () {
			show_membership_options_overlay();
			return false;
		});
    }, 10);
});

//----------------------------------------------------------
//--------------------- Paywall ----------------------------
/**
 * Show the paywall if the user has viewed too many items.
 * There may be a delay between the call and showing it.
 *
 * onshow: callback right after the paywall is shown.
 * return true if the paywall will be shown
 */
function show_paywall_if_necessary(on_show) {
	if(!user_logged_in && paywall_expired && !override_paywall) {
		setTimeout(function() {
			if(override_paywall) {
				return;
			}
			var diag = show_signup_dialog(true);
			if(on_show) {
				on_show(diag);
			}
			$("#PaywallOverlay").show(1000)
		}, 3000);
		return true;
	} else if(!user_logged_in && user_firm_name &&
		!sessionGet("sent_user_firm_inquiry", true)) {
		setTimeout(function() {
			var $user_info = $("#UserFirmInfo")
				.find(".firm_name").text(user_firm_name).end()
				.find("[name=organization]").val(user_firm_name).end()
				.find(".seemore").click(function () {
					$(this).parent().parent()
						.find("form").show().end()
						.find(".message").hide().end();
					return false;
				}).end()
			.show(500);
			setup_marketing_inquiry_form($user_info.find("form"), function () {
				$user_info.find("form").hide().end().find(".thanks").show();
				sessionSet("sent_user_firm_inquiry", true, 60*60*12, true);
				setTimeout(function () {
					$user_info.hide(500);
				}, 1000);
			});
		}, 3000);
	}

	// Show the fastcase dialog if necessary.
	if(typeof user_source != "undefined" &&
		user_source && user_source.source == "Fastcase" &&
		!sessionGet("signup_overlay_closed_" + "NewUserOverlayFastCase")) {
		var diag = show_signup_dialog(false, false, false, false,
			"NewUserOverlayFastCase");
		if(on_show) {
			on_show(diag);
		}
		return true;
	}
	return false;
}

/**
 * We like to ease the user into knowing that the paywall is coming. So
 * show them a small window before we hit them with the real paywall.
	4/2015: Turn this off, Optimizely showed we get 15% more signups without it.
	3/2020: Turn it back on just for COVID-19.
	6/2020: Only show when they are running out, this message may hurt
	 our google rankings
 */
var SHOW_PAYWALL_HELPER = true;
function show_paywall_helper() {
	// Don't bother showing the user the window unless it helps.
	if(paywall_expired)	return;
	if(override_paywall) return;
	if(!paywall_incremented) return;
	// Don't show them the first few. Our Google rankings went down in
	// March and again in June, and this may be the culprit.
	var paywall_used = paywall_total - paywall_left;
	if(paywall_used < 5 && paywall_left && paywall_left > 3) {
		return;
	}
	$("#PaywallIncrement")
		.find(".paywall_used").text(paywall_used).end()
		.find(".paywall_total").text(paywall_total).end()
		.find(".paywall_left").text(paywall_left || "0").end()
		.find(".close_overlay").click(function () {
			$("#PaywallIncrement").hide();
			return false;
		}).end()
		.show()
		.animate({ bottom: "-10px",}, 1000 );
}
if (SHOW_PAYWALL_HELPER) {
	$(document).ready(function() {
		// Dont' show it immediately, let the user get used to seeing the page.
		setTimeout('show_paywall_helper();', 3000);
	});
}


/**
 * Show the signup dialog. Each parameter corresponds to an extra message:
 * 	paywalled	Seeing the dialog b/c they reached the paywall limit.
 *  download	Seeing the dialog b/c they're trying to download a doc.
 *  tracker		Seeing the dialog b/c they're trying to add a tracker.
 * Returns the dialog if it was opened. Returns null if it was not opened, and
 * the action should be continued.
 **/
var signup_dialog_id = 'NewUserOverlayV2';
// Assigning this to V2 uses the new dialog. Makes it easy for Optimizely.
// signup_dialog_id = 'NewUserOverlayV2';
function show_signup_dialog(paywalled, download, tracker, expor, id) {
	if(user_logged_in) {
		return null;
	} else if (download && !show_paywall_if_necessary()) {
		// If downloading and they're below paywall limit, allow the download.
		return null;
	}
	var diag = borderless_dialog('#' + (id || signup_dialog_id))
		.dialog("open")
			.toggleClass("user_source", user_source ? true : false)
			.width("auto")
			// Make sure the first input field is focused.
			.find("input[name=name]")
				.val(user_source && user_source.first_last ?
					user_source.first_last : "").focus().end()
			.find("input[name=phone]")
				.val(user_source && user_source.phone ?
					user_source.phone : "").end()
			.find("input[name=email], input[name=username]")
				.val(user_source && user_source.email ?
					user_source.email : "").end()
			.find(".first").text(
				(user_source && user_source.first) || "").end()
			.find(".email").text(
				(user_source && user_source.email) || "").end()
			.find(".phone").text(
				(user_source && user_source.phone) || "").end()
			// Hide everything
			.find(".nolimit").hide().end()
			.find(".download").hide().end()
			.find(".tracker").hide().end()
			.find(".limit").hide().end();
	if(paywalled) {
		analytics_track("user", "signup_paywall");
		diag
			.find(".close_overlay").hide().end()
			.find(".limit").show();
		// Also hide the close button on the signin window.
		$('#LoginOverlay .close_overlay').hide();
	} else if (download || expor) {
		analytics_track("user", "signup_download");
		diag
			.find(".close_overlay").show().end()
			.find(".download").show();
	} else if (tracker) {
        analytics_track("user", "signup_tracker");
        diag
            .find(".close_overlay").show().end()
            .find(".tracker").show();
    } else if(id) {
		// Track any special analytics popup.
		analytics_track("user", "signup_view_" + id);
	} else {
		analytics_track("user", "signup_click");
		diag
			.find(".close_overlay").show().end()
			.find(".limit").hide().end()
			.find(".nolimit").show().end();
	}
	// Center the dialog now that everything is shown.
	var position = { my: "center center-2%", at: "center", of: window };
	diag.dialog("option", "position", position);

	// On IE placeholders disappear on focus. Make sure they're unfocused.
	var ua = window.navigator.userAgent;
	if(ua.indexOf("MSIE ") > 0 || ua.indexOf("Trident/") > 0 ||
		ua.indexOf("Edge/") > 0) {
		$(".ui-dialog").focus();
	}
	return diag;
}
function setup_signup_dialogs($obj) {
	if(!$obj) {
		$obj = $("html");
	}
	function close_parent_overlay(t) {
		var $overlay = get_parent_with_class($(t), "ui-dialog-content");
		if ($overlay) {
			// Close the overlay.
			$overlay.dialog("close");
			// Record the fact that this overlay was closed.
			sessionSet("signup_overlay_closed_" + $overlay.attr('id'), true);
		}
		return false;
	}

	$obj

	.find(".signup").unbind("click").click(function (ev) {
		if($(this).hasClass('nosignup')) {
			return;
		}
		close_parent_overlay(this);
		ev.preventDefault();
		show_signup_dialog($(this).hasClass("paywalled"),
							$(this).hasClass("download"),
							$(this).hasClass("tracker"));
		return false;
	}).end()

	.find(".close_overlay").click(function() {
		$(".formError").remove();
		return close_parent_overlay(this);
	}).end()

	.find(".signin").unbind("click").click(function (ev) {
		// Close the signup dialog if it exists.
		close_parent_overlay(this);
		ev.preventDefault();
		// user_logged_in must be defined
		if(user_logged_in) {
			window.location.replace("/dockets/");
		} else {
			borderless_dialog('div#LoginOverlay').dialog("open")
				.find("input[name=username]").focus();
		}
	}).end()

	.find(".ForgotPassword").unbind("click").click(function() {
		close_parent_overlay(this);
		borderless_dialog("#ForgotPasswordOverlay").dialog("open")
			.find("input[name=username]").focus();
		return false;
	}).end()

	.find("a[title]").powerTip({
		smartPlacement: true,
		popupClass : 'small_powertip',
	}).end()

	// createlogin forms are for both logging in and new users
	.find('form.createlogin').ajaxForm({
		beforeSubmit: function(arr, form) {
			$('form.createlogin .loader').css('visibility', 'visible');
			$('form.createlogin input[type=submit]').addClass('disabled');
		},
		success:  function (resp)  {
			// Hide the throbber only if there is an error
			if(resp.success) {
				analytics_track("user", resp.created ? "new_user" : "login");
				// Put a small delay on the reload so async analytics works.
				setTimeout(function () {
					if(resp.redirect) {
						window.location = resp.redirect;
					} else {
						window.location.reload(true);
					}
				}, 10);
			} else {
				$('form.createlogin .loader').css('visibility', 'hidden');
				$('form.createlogin input[type=submit]').removeClass('disabled');
				show_error_usermsg(resp.error);
			}
		},
		error:		function (dum, the_error) {
			$('form.createlogin .loader').css('visibility', 'hidden');
			show_error_usermsg(the_error);
			$('form.createlogin input[type=submit]').removeClass('disabled');
			return false;
		},
		dataType: 'json'
	}).end().find("#general_sso").unbind("click").click(function(ev) {
		ev.preventDefault();
		user_email = $("form.createlogin input[id=login_email]").val();
		borderless_dialog("div#CorporateSSO").dialog("open").find("input[name=sso_email]").val(user_email).focus();
	  }).end().find("#general_sso_login").unbind("click").click(function(ev) {
		ev.preventDefault();
		user_email = $("form.createlogin input[name=username]").val();
		borderless_dialog("div#CorporateSSOLogin").dialog("open").find("input[name=sso_email]").val(user_email).focus();
	  });
}

var calendly_setup = false;
/**
 * A click handler for calendly links. Should be used with jquery query as
 * follows: $("<target_button").click(calendly_demo_click); Will launch a
 * calendly form when displayed.
 * @returns {boolean}
 */
function calendly_demo_click() {
	// This should be called where "this" is the object being acted on.
	var $this = $(this);
    // Add a changing class to the link to show we're doing something.
    $this.addClass("changing");
    if(!calendly_setup) {
        // Register for events if there is a calendly event.
        onEvent("message", function _on_calendly_event(e) {
            if (e.data.event && e.data.event.indexOf('calendly') === 0) {
                analytics_track("user", "signup_calendly", e.data.event);
            }
        });
        load_css("https://calendly.com/assets/external/widget.css");
        calendly_setup = true;
    }
    $.getScript("https://calendly.com/assets/external/widget.js", function () {
    	// Remove the changing class.
    	$this.removeClass("changing");
    	var url = 'https://calendly.com/docket-alarm-demo-team/';
    	// Figure out which calendly link to send them to.
    	url += $this.hasClass("analytics-workbench") ?
			"docket-alarm-litigation-analytics" : "docket-alarm-demo";
        // Add styling.
    	url += '?text_color=4d4d4f&primary_color=3066b0';
    	// Add in the user's name to make it easier for them to accept.
    	if(user_first_name && user_last_name && user_first_name.length && user_last_name.length) {
    		url += "&name=" + encodeURIComponent(user_first_name + " " + user_last_name);
		} else if(user_first_name && user_first_name.length) {
    		url += "&name=" + encodeURIComponent(user_first_name);
		}
    	// Add in the user's email if we have it.
    	if(user_logged_in && user_logged_in != true && user_logged_in.length) {
    		url += "&email=" + encodeURIComponent(user_logged_in);
		}
    	Calendly.showPopupWidget(url);
    });
    return false;
}

export {
    toggle_membership_window,

	set_matter_number_error,

	// Billing Related
	update_billing_info,
	update_billing_session_info,
	setup_credit_card_form,
	// setup_billing_pane,
	// disable_pacer_submenus,
	// init_billing_info_callback,

	show_paywall_if_necessary,
	show_paywall_helper,
	show_signup_dialog,
	setup_signup_dialogs,
	// calendly_demo_click,

	show_membership_options_overlay,
}